import * as React from 'react';
import {
  Box,
  Button,
  ContentBoxes,
  ContentBox,
  Cluster,
  DateTime,
  Dialog,
  Heading,
  Icon,
  FormItem,
  ImageWrapper,
  Notification,
  Paragraph,
  Popover,
  Spinner,
  Status,
  Table,
  TdLink,
  TdMultilineTruncate,
  Template,
  TextInput,
  TFormFields,
  TIcons,
  Toast,
  ITableCol,
} from '@pluto-tv/assemble';

import {useAppPermissions} from 'app/permissions';

import {TableActions} from 'components/tableActions';
import ChannelList from 'components/channelList';
import ChannelEditForm from 'components/channelEditForm';
import {IFeaturedGroup, IContentItem} from 'models/featuredGroup';
import {IChannel} from 'models/channels';
import {reorderList} from 'helpers/dragAndDrop';
import {differenceBy} from 'lodash-es';
import {rewriteImageUrl} from 'utils/imageUtils';
import {getPrefixedUrl} from 'routes';

interface IFeaturedGroupDetailsProps {
  form: TFormFields<IFeaturedGroup>;
  model: Partial<IFeaturedGroup>;
  onBlur: (fieldName: keyof IFeaturedGroup, setVal?: boolean | undefined) => void;
  onChange: <K extends keyof IFeaturedGroup>(fieldName: K, value: IFeaturedGroup[K]) => void;
  setFields: (partialModel: Partial<IFeaturedGroup>) => void;
}

const FeaturedGroupDetails = ({form, model, onBlur, onChange, setFields}: IFeaturedGroupDetailsProps): JSX.Element => {
  const {ableTo} = useAppPermissions();

  const [selectedChannelsFromModal, setSelectedChannelsFromModal] = React.useState<IChannel[]>([]);

  const canEdit = ableTo('FEATURED_GROUPS_EDIT');

  const [isAddChannelOpen, setIsAddChannelOpen] = React.useState(false);

  const cancelAddChannel = () => {
    setIsAddChannelOpen(false);
  };

  const handleAddChannel = () => {
    setFields({
      contentItems: [
        ...(model.contentItems || []),
        ...selectedChannelsFromModal.map(channel => {
          return {
            content: channel,
            contentType: 'channel',
            partnerGrid: false,
            type: '',
            strategy: '',
            notes: '',
          };
        }),
      ],
    });

    Toast.success('Channels have been added successfully.');
    setSelectedChannelsFromModal([]);
  };

  React.useEffect(() => {
    if (isAddChannelOpen) {
      setTimeout(() => document.getElementById('ChannelSearchTitle')?.focus());
    }
  }, [isAddChannelOpen]);

  const [editChannelPopoverOpen, setEditChannelPopoverOpen] = React.useState({});

  const handleEditChannelClick = React.useCallback(
    (icon: TIcons, index: number) => {
      if (icon === 'delete') {
        const contentItemsUpdated = [...(model.contentItems || [])];
        contentItemsUpdated.splice(index, 1);
        setFields({contentItems: contentItemsUpdated});
      } else if (icon === 'edit') {
        setEditChannelPopoverOpen({[index]: true});
      }
    },
    [model.contentItems, setFields],
  );

  const handleEditChannelUpdate = React.useCallback(
    (val, index) => {
      if (model?.contentItems?.length) {
        const aux = [...model.contentItems];
        aux[index] = {
          ...val,
        };
        setFields({contentItems: [...aux]});
        setEditChannelPopoverOpen({[index]: false});
        Toast.success(`Channel entry has been updated.`);
      }
    },
    [model.contentItems, setFields],
  );

  const handleDropRow = (_from: string, _to: string, fromIndex: number[], toIndex: number) => {
    setFields({contentItems: reorderList(fromIndex, toIndex, model.contentItems || [])});
  };

  const handleSelect = React.useCallback((rows: IChannel[]) => {
    setSelectedChannelsFromModal(prev => {
      const newlySelectedChannels = differenceBy(rows, prev, 'id');
      const deselectedChannels = differenceBy(prev, rows, 'id');

      let newSelection = prev.filter(channel => !deselectedChannels.includes(channel));
      newSelection = [...newSelection, ...newlySelectedChannels];
      return newSelection;
    });
  }, []);

  const columns: ITableCol<IContentItem>[] = React.useMemo(
    () => [
      {
        label: 'Ch. No.',
        colWidth: '5.5rem',
        colMinWidth: '5.5rem',
        transform: row => row.content.number,
      },
      {
        label: 'Channel Name',
        colMinWidth: '15rem',
        transform: row => {
          return (
            <Cluster wrap={false} space='small' align='center'>
              <ImageWrapper
                backgroundColor='black'
                height='45px'
                width='75px'
                src={rewriteImageUrl(row.content.featuredImage?.path)}
                alt={row.content.featuredImage?.altText || row.content.featuredImage?.title}
                loading='lazy'
              />
              <TdLink
                row={row}
                title={row.content.name || ''}
                url={getPrefixedUrl(`/channels/${row.content.id}/details`)}
                target='_blank'
                rel='opener'
              />
            </Cluster>
          );
        },
      },
      {
        label: 'Published',
        sortable: true,
        colWidth: '8.5rem',
        colMinWidth: '8.5rem',
        transform: row => (
          <Status
            label={row.content.published ? 'Published' : 'Unpublished'}
            state={row.content.published ? 'success' : 'neutral'}
          />
        ),
      },
      {
        label: 'Main Categories',
        colWidth: '10rem',
        colMinWidth: '10rem',
        transform: row => row.content.categories?.map(cat => cat.name).join(','),
      },
      {
        label: 'Legacy Category',
        colWidth: '10rem',
        colMinWidth: '10rem',
        transform: row => row.content.category,
      },
      {
        label: 'Partner Grid',
        colWidth: '7.8rem',
        colMinWidth: '7.8rem',
        transform: row => (row.partnerGrid ? 'Yes' : 'No'),
      },
      {
        label: 'Type',
        colWidth: '9rem',
        colMinWidth: '9rem',
        field: 'type',
      },
      {
        label: 'Strategy',
        colWidth: '7.5rem',
        colMinWidth: '7.5rem',
        field: 'strategy',
      },
      {
        label: 'Notes',
        // colWidth: '13rem',
        colMinWidth: '10rem',
        transform: row => <TdMultilineTruncate color='secondary' row={row} text={row.notes} truncateOnLine={2} />,
      },
      ...(canEdit
        ? [
            {
              label: 'Actions',
              transform: (row, _col, index) => (
                <TableActions
                  row={row}
                  icons={[]}
                  deleteOption={canEdit}
                  onClick={(_row, icon) => handleEditChannelClick(icon, index)}
                >
                  <Popover
                    appendToBody={true}
                    permission={canEdit ? '' : 'hidden'}
                    manualTrigger={true}
                    visible={editChannelPopoverOpen[index]}
                    onClickOutside={() => setEditChannelPopoverOpen({})}
                  >
                    <Template label='trigger'>
                      <Icon
                        space='small'
                        icon='edit'
                        id='editAction'
                        onClick={() => handleEditChannelClick('edit', index)}
                      />
                    </Template>
                    <Template label='popover'>
                      <ChannelEditForm
                        visible={editChannelPopoverOpen[index]}
                        onCancel={() => setEditChannelPopoverOpen({})}
                        value={row}
                        title={row.content.name}
                        onSave={val => handleEditChannelUpdate(val, index)}
                      />
                    </Template>
                  </Popover>
                </TableActions>
              ),
            },
          ]
        : []),
    ],
    [canEdit, editChannelPopoverOpen, handleEditChannelClick, handleEditChannelUpdate],
  );

  const disabledChannels = React.useMemo(
    () => (model.contentItems || [])?.map(contentItem => contentItem.content),
    [model.contentItems],
  );

  const channelsCount = (model.contentItems?.length || 0) + selectedChannelsFromModal.length;

  return (
    <ContentBoxes layout='cover' coverScrolling={true}>
      <Template label='contentBoxesHeader'>
        <ContentBox title='Details'>
          <form onSubmit={ev => ev.preventDefault()}>
            <Cluster space='medium' fullWidth={true} id='contentNameHeader'>
              <Box width='35%'>
                <FormItem
                  {...form.name}
                  label='Name'
                  onBlur={() => onBlur('name')}
                  permission={canEdit ? '' : 'disabled'}
                >
                  <TextInput id='groupName' value={model.name} onChange={value => onChange('name', value)} />
                </FormItem>
              </Box>
              <FormItem
                {...form.start}
                label='Start Date'
                onBlur={() => onBlur('start')}
                permission={canEdit ? '' : 'disabled'}
              >
                <DateTime
                  time={true}
                  id='startDate'
                  value={model.start ? new Date(model.start) : undefined}
                  onChange={value => setFields({start: value as Date})}
                />
              </FormItem>
            </Cluster>
          </form>
        </ContentBox>
      </Template>
      <Template label='contentBoxesCover'>
        <ContentBox>
          <Template label='header'>
            <Cluster justify='space-between' align='center' space='medium'>
              <Cluster space='small' align='flex-end'>
                <Heading level='h3' color='secondary'>
                  Channels
                </Heading>
                <Heading level='h4' color='secondary'>
                  {`${channelsCount} ${channelsCount === 1 ? 'Item' : 'Items'}`}
                </Heading>
              </Cluster>
              <Button
                id='addChannel'
                type='primary'
                permission={canEdit ? '' : 'hidden'}
                onClick={() => setIsAddChannelOpen(true)}
              >
                + Add Channel
              </Button>
              <Dialog isOpen={isAddChannelOpen} onClose={cancelAddChannel} width='100%' height='100%'>
                <Template label='header'>
                  <Heading level='h2'>Add Channels</Heading>
                </Template>
                <Template label='body'>
                  <ChannelList
                    actionsCol={false}
                    addNewChannel={false}
                    deviceUpdates={false}
                    checkboxCol='multiple'
                    filterByArchived={false}
                    inModal
                    isSearchExpanded
                    showFavoriteSearch
                    searchRegion={model.activeRegion}
                    disableChannels={disabledChannels}
                    onSelect={handleSelect}
                    hideArchivedChannels
                  />
                </Template>
                <Template label='footer'>
                  <Cluster justify='space-between'>
                    <div></div>
                    <Cluster space='small'>
                      <Button
                        type='primary'
                        permission={!selectedChannelsFromModal.length ? 'disabled' : ''}
                        onClick={handleAddChannel}
                      >
                        Add Channels
                      </Button>
                    </Cluster>
                  </Cluster>
                </Template>
              </Dialog>
            </Cluster>
          </Template>
          <Template label='content'>
            <Table<IContentItem>
              loading={!model}
              predicate={row => row.content.id}
              fixedHeader
              wrapContent
              highlightNew
              draggable={canEdit}
              onDrop={handleDropRow}
              dragKey='channelContentItemsTable'
              dropKeys={['channelContentItemsTable']}
              id='channelContentItemsTable'
              cols={columns}
              rows={model.contentItems || []}
            >
              <Template label='loading'>
                <Cluster space='small' align='center'>
                  <Spinner />
                  <Paragraph>Loading Channels</Paragraph>
                </Cluster>
              </Template>
              <Template label='empty'>
                <Notification type='warning'>There are no channels currently available.</Notification>
              </Template>
            </Table>
          </Template>
        </ContentBox>
      </Template>
    </ContentBoxes>
  );
};

export default FeaturedGroupDetails;
