import * as React from 'react';
import {useHistory} from 'react-router-dom';
import {debounce} from 'lodash-es';

import userRoutes from 'routes/admin.routes';
import NotAuthorized from 'components/notAuthorized';
import {useFindQuery, useUpdateMutation} from 'features/users/usersApi';
import {TableActions} from 'components/tableActions';

import {
  Box,
  Cluster,
  Cover,
  Heading,
  Notification,
  Pagination,
  Paragraph,
  Spinner,
  Table,
  TdLink,
  Template,
  TextInput,
  Toggle,
  Toast,
  trimModel,
  ITableCol,
} from '@pluto-tv/assemble';

import {IUser} from 'models/users';
import {useAppPermissions} from 'app/permissions';
import {withThousandsSeparator} from 'utils/thousands-separator';
import {getPrefixedUrl} from 'routes';

interface IUserToggle {
  row: IUser;
  onChange: (val: boolean, row: IUser) => void;
}

const UserActiveView = React.memo(({row}: IUserToggle) => <>{row.active ? 'Active' : 'Inactive'}</>);

const UserActiveToggle = React.memo(({row, onChange}: IUserToggle) => {
  const [userActive, setUserActive] = React.useState(row.active);
  const [label, setLabel] = React.useState(row.active ? 'Active' : 'Inactive');

  const toggleChanged = async (val: boolean) => {
    try {
      await onChange(val, row);
      setLabel(val ? 'Active' : 'Inactive');
    } catch (e) {
      // This will trigger the Toggle to switch back when it fails
      setUserActive(val);
      setTimeout(() => {
        setUserActive(!val);
        setLabel(!val ? 'Active' : 'Inactive');
      });
    }
  };

  React.useEffect(() => {
    setUserActive(row.active);
    setLabel(row.active ? 'Active' : 'Inactive');
  }, [row]);

  return <Toggle value={userActive} onChange={val => toggleChanged(val)} label={label} id='toggleUserActive'></Toggle>;
});

interface ISearchState {
  page: number;
  rowsPerPage: number;
  search: string;
}

const searchReducer = (prevState: ISearchState, data: Partial<ISearchState>) => {
  return {
    ...prevState,
    ...data,
  };
};

export default (): JSX.Element => {
  const history = useHistory();

  const [params, setParamsDispatch] = React.useReducer<React.Reducer<ISearchState, Partial<ISearchState>>>(
    searchReducer,
    {search: '', page: 0, rowsPerPage: 25},
  );

  const {page, rowsPerPage, search} = params;

  const {ableTo} = useAppPermissions();

  const canUserEdit = ableTo('USER_EDIT');

  if (!ableTo('USER_VIEW')) {
    return <NotAuthorized />;
  }

  const [updateUser] = useUpdateMutation();

  const {data: users, isFetching} = useFindQuery(
    {
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      search: search,
    },
    {refetchOnMountOrArgChange: true},
  );

  React.useLayoutEffect(() => {
    const searchUserInput = document.getElementById('userSearchField');
    searchUserInput?.focus({preventScroll: true});
  }, []);

  const handleEdit = (user: IUser) => {
    history.push(userRoutes.paths.userEditPage.replace(':id', user._id));
  };

  const doSearch = debounce((val: string) => {
    setParamsDispatch({page: 0, search: val});
  }, 500);

  const toggleActiveUser = async (val: boolean, user: IUser) => {
    if (!canUserEdit) return;

    const {_id: id, ...props} = trimModel(user, 'displayName', 'email');

    try {
      const res = await updateUser({
        id,
        user: {
          ...props,
          active: val,
        },
      });

      if ((res as any).error) {
        throw Error((res as any).error);
      }

      Toast.success('Success', 'User Updated');
    } catch (e) {
      Toast.error('Error', 'Error Updating User');
      throw Error();
    }
  };

  return (
    <>
      <Cover scrolling={true} gutter='large' coverTemplateHeight='100%' padding={{mobile: 'medium', wide: 'large'}}>
        <Template label='header'>
          <Cluster justify='space-between' align='center' space='medium'>
            <Cluster align='end' space='small'>
              <Heading level='h1'>Users</Heading>
              <Paragraph color='secondary'>{withThousandsSeparator(users?.metadata.totalCount || 0)} Items</Paragraph>
            </Cluster>
            <Cluster space='small'>
              <TextInput
                id='userSearchField'
                iconLeft='search'
                size='small'
                placeholder='Search Users'
                onChange={val => doSearch(val)}
              />
            </Cluster>
          </Cluster>
        </Template>
        <Template label='cover'>
          <Box
            background='pewter'
            borderTop={true}
            borderSize='0.125rem'
            borderColor='cavern'
            paddingTop={(users?.data.length as number) > 0 ? 'xsmall' : 'medium'}
            paddingBottom='none'
            paddingX='large'
            fullHeight={true}
          >
            <Table
              id='usersTable'
              loading={isFetching}
              fixedHeader={true}
              cols={[
                {
                  label: 'Name',
                  transform: row => (
                    <TdLink
                      row={row}
                      title={row.displayName}
                      url={getPrefixedUrl(userRoutes.paths.userEditPage.replace(':id', row._id))}
                      onClick={handleEdit}
                    />
                  ),
                },
                {
                  label: 'Email',
                  field: 'email',
                },
                {
                  label: 'Role(s)',
                  colMaxWidth: '125rem',
                  transform: row => (row && row.roles && row.roles.length > 0 ? row.roles.join(', ') : ''),
                },
                {
                  label: 'Account Created',
                  transform: row =>
                    new Date(row.createdAt).toLocaleString('en-US', {year: 'numeric', month: 'long', day: 'numeric'}),
                },
                {
                  label: 'Status',
                  colWidth: '9.375rem',
                  transform: row =>
                    canUserEdit ? (
                      <UserActiveToggle row={row} onChange={(val: boolean, row) => toggleActiveUser(val, row)} />
                    ) : (
                      <UserActiveView row={row} onChange={(val: boolean, row) => toggleActiveUser(val, row)} />
                    ),
                },
                ...(canUserEdit
                  ? [
                      {
                        label: 'Actions',
                        colWidth: '6.25rem',
                        transform: row => (
                          <TableActions
                            icons={canUserEdit ? ['edit'] : []}
                            row={row}
                            onClick={row => handleEdit(row)}
                          />
                        ),
                      } as ITableCol<IUser>,
                    ]
                  : []),
              ]}
              rows={users?.data}
            >
              <Template label='loading'>
                <Cluster space='small' align='center'>
                  <Spinner />
                  <Paragraph>Loading Users</Paragraph>
                </Cluster>
              </Template>
              <Template label='empty'>
                <Notification type='warning'>There are no users currently available.</Notification>
              </Template>
            </Table>
          </Box>
        </Template>
        <Template label='footer'>
          <Cluster justify='space-between'>
            <div></div>
            {(users?.data.length as number) > 0 && (
              <Pagination
                perPage={rowsPerPage as 25 | 50 | 75 | 100}
                currentPage={page}
                total={users?.metadata.totalCount || 0}
                onPageChange={page => setParamsDispatch({page})}
                onPerPageChange={rowsPerPage => {
                  setParamsDispatch({rowsPerPage, page: 0});
                }}
              />
            )}
          </Cluster>
        </Template>
      </Cover>
    </>
  );
};
