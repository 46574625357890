import * as React from 'react';
import {useHistory} from 'react-router-dom';

import adminRoutes from 'routes/admin.routes';
import NotAuthorized from 'components/notAuthorized';
import {useFindQuery} from 'features/appnames/appnamesApi';
import {TableActions} from 'components/tableActions';

import {
  Box,
  Button,
  Cluster,
  Cover,
  Heading,
  ITableCol,
  Notification,
  Pagination,
  Paragraph,
  Pill,
  Spinner,
  Table,
  TdLink,
  Template,
} from '@pluto-tv/assemble';

import {IAppName} from 'models/appNames';
import {useAppPermissions} from 'app/permissions';
import {withThousandsSeparator} from 'utils/thousands-separator';
import {getPrefixedUrl} from 'routes';

const TdPills = ({row}) =>
  row && row.devices && row.devices.length > 0 ? (
    <Cluster space='xsmall'>
      {row.devices.map(device => (
        <Pill key={device} label={device}></Pill>
      ))}
    </Cluster>
  ) : (
    <>No devices</>
  );

export default (): JSX.Element => {
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const {ableTo, permissions} = useAppPermissions();

  if (!ableTo('APPNAME_VIEW')) {
    return <NotAuthorized />;
  }

  const {data: appNames, isFetching} = useFindQuery(
    {
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      sort: 'createdAt:desc',
    },
    {refetchOnMountOrArgChange: true},
  );

  const handleCreate = () => {
    history.push(adminRoutes.paths.appNameEditPage.replace(':id', 'new'));
  };

  const handleEdit = (role: IAppName) => {
    history.push(adminRoutes.paths.appNameEditPage.replace(':id', role._id));
  };

  return (
    <>
      <Cover scrolling={true} gutter='large' coverTemplateHeight='100%' padding={{mobile: 'medium', wide: 'large'}}>
        <Template label='header'>
          <Cluster justify='space-between' align='center' space='medium'>
            <Cluster align='end' space='small'>
              <Heading level='h1'>App Profile Management</Heading>
              <Paragraph color='secondary'>
                {withThousandsSeparator(appNames?.metadata.totalCount || 0)} Items
              </Paragraph>
            </Cluster>
            <Button type='primary' onClick={() => handleCreate()} permission={permissions.APPNAME_CREATE}>
              + New App Name
            </Button>
          </Cluster>
        </Template>
        <Template label='cover'>
          <Box
            background='pewter'
            borderTop={true}
            borderSize='0.125rem'
            borderColor='cavern'
            paddingTop={(appNames?.data.length as number) > 0 ? 'xsmall' : 'medium'}
            paddingBottom='none'
            paddingX='large'
            fullHeight={true}
          >
            <Table
              loading={isFetching}
              fixedHeader={true}
              wrapContent={true}
              cols={[
                {
                  label: 'App Name',
                  transform: row => (
                    <TdLink
                      row={row}
                      title={row.name}
                      url={getPrefixedUrl(adminRoutes.paths.appNameEditPage.replace(':id', row._id))}
                      onClick={handleEdit}
                    />
                  ),
                },
                {
                  label: 'Associated Devices',
                  transform: row => <TdPills row={row} />,
                },
                ...(ableTo('APPNAME_EDIT')
                  ? [
                      {
                        label: 'Actions',
                        colWidth: '6.25rem',
                        transform: row => (
                          <TableActions
                            row={row}
                            icons={ableTo('APPNAME_EDIT') ? ['edit'] : []}
                            onClick={(row, icon) => {
                              switch (icon) {
                                case 'edit':
                                  handleEdit(row);
                                  break;
                                default:
                              }
                            }}
                          />
                        ),
                      } as ITableCol<IAppName>,
                    ]
                  : []),
              ]}
              rows={appNames?.data}
            >
              <Template label='loading'>
                <Cluster space='small' align='center'>
                  <Spinner />
                  <Paragraph>Loading App Names</Paragraph>
                </Cluster>
              </Template>
              <Template label='empty'>
                <Notification type='warning'>There are no App Names currently available.</Notification>
              </Template>
            </Table>
          </Box>
        </Template>
        <Template label='footer'>
          <Cluster justify='space-between'>
            <div></div>
            {(appNames?.data.length as number) > 0 && (
              <Pagination
                perPage={rowsPerPage as 25 | 50 | 75 | 100}
                currentPage={page}
                total={appNames?.metadata.totalCount || 0}
                onPageChange={page => setPage(page)}
                onPerPageChange={perPage => {
                  setRowsPerPage(perPage);
                  setPage(0);
                }}
              />
            )}
          </Cluster>
        </Template>
      </Cover>
    </>
  );
};
