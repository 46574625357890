import * as React from 'react';
import {Provider} from 'react-redux';
import '@pluto-tv/assemble/dist/style.css';

import Layout from 'app/layout/Main';
import {isCommandCenter} from 'helpers/isCommandCenter';

import {store} from './store';
import './App.css';

const initCCHeader = async () => {
  const {Header} = await import('@pluto-tv/lib-command-center-ts');

  Header().init();
};

export default (): JSX.Element => {
  if (isCommandCenter) {
    initCCHeader();
  } else {
    const ccSharedHeader = document.getElementById('cc-shared-header');

    if (ccSharedHeader) {
      ccSharedHeader.style.display = 'none';
    }
  }

  return (
    <Provider store={store}>
      <Layout />
    </Provider>
  );
};
