import {IValidatorField} from '@pluto-tv/assemble';

import {
  ICarouselConfig,
  ICarouselConfigCommonProps,
  ICarouselConfigCreate,
  ICarouselConfigSearch,
  ICarouselConfigSourceService,
} from 'models/carouselConfigs';

export const carouselConfigSearchValidator: IValidatorField<ICarouselConfigSearch>[] = [
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'region',
    label: 'Active Region',
  },
];

export const sourceServiceCarousel: IValidatorField<Partial<ICarouselConfigCreate>>[] = [
  {
    name: 'source',
    label: 'Carousel Source',
    required: true,
    validators: [
      (source: string): string | undefined => {
        if (!source || !source.trim().length) {
          return 'Carousel Source is required';
        }
      },
    ],
  },
  {
    name: 'service',
    label: 'Carousel Service',
    required: true,
    validators: [
      (service: string): string | undefined => {
        if (!service || !service.trim().length) {
          return 'Carousel Service is required';
        }
      },
    ],
  },
  {
    name: 'associatedCarousel',
    label: 'Associated Carousel Item',
  },
];

export const carouselConfigSimple: IValidatorField<Partial<ICarouselConfigCommonProps>>[] = [
  {
    name: 'name',
    label: 'Carousel Name',
    required: true,
    validators: [
      (name: string): string | undefined => {
        if (!name || !name.trim().length) {
          return 'Carousel Name is required';
        } else if (name && name.trim().length > 100) {
          return 'Carousel Name must be less than 100 characters';
        }
      },
    ],
  },
  {
    name: 'displayModel',
    label: 'Display Model',
    required: true,
    validators: [
      (displayModel: string): string | undefined => {
        if (!displayModel || !displayModel.trim().length) {
          return 'Display Model is required';
        }
      },
    ],
  },
  {
    name: 'maxDisplayedTilesCount',
    label: 'Max Tiles Displayed',
    validators: [
      (maxTilesDisplayed: number): string | undefined => {
        if (Number.isInteger(maxTilesDisplayed) && maxTilesDisplayed <= 0) {
          return 'Max Tiles Displayed must not be less than 1';
        }
      },
    ],
  },
  {
    name: 'onClickAction',
    label: 'On Click Action',
    required: true,
    validators: [
      (onClickAction: string): string | undefined => {
        if (!onClickAction || !onClickAction.trim().length) {
          return 'On Click Action is required';
        }
      },
    ],
  },
  {
    name: 'refreshOnUserAction',
    label: 'Refresh On User Action',
  },
  {
    name: 'activeRegion',
    label: 'Active Region',
    required: true,
    validators: [
      (region: string): string | undefined => {
        if (!region) {
          return 'Active Region is required';
        }
      },
    ],
  },
];

export const carouselConfigCreateValidator: IValidatorField<ICarouselConfigCreate>[] = [
  ...carouselConfigSimple,
  ...sourceServiceCarousel,
  {
    name: 'onClickActionOverrides',
    label: 'On Click Action Override',
  },
];

export const carouselConfigDetailsValidator: IValidatorField<ICarouselConfig>[] = [
  ...carouselConfigSimple,
  {
    name: 'onClickActionOverrides',
    label: 'On Click Action Override',
  },
];

export const carouselConfigDetailsSourceServiceValidator: IValidatorField<ICarouselConfigSourceService>[] = [
  {
    name: 'source',
    label: 'Carousel Source',
    required: true,
    validators: [
      (source: string): string | undefined => {
        if (!source || !source.trim().length) {
          return 'Carousel Source is required';
        }
      },
    ],
  },
  {
    name: 'service',
    label: 'Carousel Service',
    required: true,
    validators: [
      (service: string): string | undefined => {
        if (!service || !service.trim().length) {
          return 'Carousel Service is required';
        }
      },
    ],
  },
  {
    name: 'associatedCarousel',
    label: 'Associated Carousel Item',
  },
  {
    name: 'policy',
    label: 'Policy',
  },
  {
    name: 'position',
    label: 'Position',
    validators: [
      (position: number): string | undefined => {
        if (typeof position != 'number') {
          return 'Position number must be a valid number';
        } else if (position < 0) {
          return 'Position must be 0 or greater.';
        }
      },
    ],
  },
];
