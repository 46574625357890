import * as React from 'react';
import {ITableCol, Pill, Stack, Table, TdLink, TSize} from '@pluto-tv/assemble';
import {ICarouselConfig, ICarouselConfigSearch} from 'models/carouselConfigs';
import {TableActions} from 'components/tableActions';
import carouselRoutes from 'routes/programming.routes';
import {useAppPermissions} from 'app/permissions';
import {
  convertApisToSources,
  convertApisToServices,
  unknownService,
  unknownSource,
} from 'components/carouselList/helpers/SourceServiceHelper';
import {TSortDirection} from 'models/generic';
import {getPrefixedUrl} from 'routes';

interface ICarouselListTableProps {
  actionsCol?: boolean;
  isSearchActive: boolean;
  carouselConfigItems?: ICarouselConfig[];
  isLazyLoading: boolean;
  isError: boolean;
  isLoading: boolean;
  searchParams?: ICarouselConfigSearch;
  search: (params: ICarouselConfigSearch) => void;
  lazyLoad: () => void;
  onEdit: (id: string) => void;
  onDelete: (id: string) => Promise<void>;
}

const TABLE_COLUMN_NAME = {
  'Carousel Configuration': 'name',
  createdAt: 'createdAt',
} as const;

const TABLE_COLUMN_VALUE = {
  name: 'Carousel Configuration',
  createdAt: 'createdAt',
} as const;

const StackedPills = <T extends string>({values}: {values: T[]}): React.ReactElement => {
  return (
    <Stack space='small'>
      {values.map(value => {
        return (
          <Pill
            key={value}
            label={value}
            backgroundColor={value === unknownSource || value === unknownService ? '#991117' : undefined}
          />
        );
      })}
    </Stack>
  );
};

const CarouselListTable = ({
  onEdit,
  onDelete,
  actionsCol = true,
  carouselConfigItems,
  lazyLoad,
  isLazyLoading,
  isError,
  isLoading,
  search,
  searchParams,
}: ICarouselListTableProps): JSX.Element => {
  const {ableTo} = useAppPermissions();

  const columns = React.useMemo(
    () => [
      {
        label: 'Carousel Configuration',
        colWidth: '45rem' as TSize,
        sortable: true,
        transform: (row: ICarouselConfig) => (
          <TdLink
            row={row}
            title={row.name}
            url={getPrefixedUrl(carouselRoutes.paths.carouselEditDetailsPage.replace(':id', row.id))}
            onClick={() => onEdit(row.id)}
          />
        ),
      },
      {
        label: 'Active Region',
        transform: (row: ICarouselConfig) =>
          row.activeRegion?.length
            ? row.activeRegion.length === 1
              ? row.activeRegion[0].toUpperCase()
              : row.activeRegion.join(',')
            : 'ALL',
        colMinWidth: '15rem' as TSize,
      },
      {
        label: 'Carousel Source',
        transform: (row: ICarouselConfig) => {
          const sources = convertApisToSources(row.apis);
          return sources.length ? <StackedPills values={sources} /> : null;
        },
        colMinWidth: '25rem' as TSize,
      },
      {
        label: 'Carousel Service',
        transform: (row: ICarouselConfig) => {
          const services = convertApisToServices(row.apis);
          return services.length ? <StackedPills values={services} /> : null;
        },
        colWidth: '25rem' as TSize,
      },

      ...(ableTo('CAROUSEL_EDIT') || (ableTo('CAROUSEL_DELETE') && actionsCol)
        ? [
            {
              label: 'Actions',
              colWidth: '6.25rem',
              transform: row => (
                <TableActions
                  row={row}
                  icons={ableTo('CAROUSEL_EDIT') ? ['edit'] : []}
                  deleteOption={ableTo('CAROUSEL_DELETE')}
                  onClick={(row, icon) => {
                    switch (icon) {
                      case 'edit':
                        onEdit(row.id);
                        break;
                      case 'delete':
                        onDelete(row.id);
                        break;
                      default:
                    }
                  }}
                />
              ),
            } as ITableCol<ICarouselConfig>,
          ]
        : []),
    ],
    [actionsCol, ableTo, onEdit, onDelete],
  );

  const changeSort = (columnName: keyof typeof TABLE_COLUMN_NAME) => {
    let newSort: TSortDirection;
    const selectedCol: string = TABLE_COLUMN_NAME[columnName];

    if (selectedCol === searchParams?.sortBy) {
      if (searchParams?.sortDirection === 'asc') {
        newSort = 'dsc';
      } else {
        newSort = 'asc';
      }
    } else {
      newSort = 'dsc';
    }

    search({
      sortBy: selectedCol,
      sortDirection: newSort,
      region: searchParams?.region || [],
    });
  };

  return (
    <Table<ICarouselConfig>
      id='carouselConfigurationsList'
      virtual
      fixedHeader={true}
      loading={isLoading}
      wrapContent={true}
      onLazyLoad={lazyLoad}
      lazyLoading={isLazyLoading}
      lazyLoadingMsg='Loading more items...'
      lazyLoadScrollOffset={5}
      sortDir={searchParams?.sortDirection}
      sortCol={searchParams?.sortBy ? TABLE_COLUMN_VALUE[searchParams.sortBy] : undefined}
      onSort={colName => changeSort(colName as keyof typeof TABLE_COLUMN_NAME)}
      emptyMsg={
        isError
          ? 'There was an error retrieving carousel configurations. Please try again later.'
          : !carouselConfigItems?.length && !isLoading
          ? 'No carousel configurations found.'
          : undefined
      }
      cols={columns}
      rows={carouselConfigItems}
    />
  );
};

export default CarouselListTable;
