import * as React from 'react';
import {
  TdLink,
  Popover,
  Template,
  Box,
  Stack,
  Cluster,
  Heading,
  Divider,
  ImageWrapper as AssembleImageWrapper,
  TdMultilineTruncate,
} from '@pluto-tv/assemble';
import {IFeaturedGroup} from 'models/featuredGroup';
import {rewriteImageUrl} from 'utils/imageUtils';
import {formatDateWithTimezone} from 'utils/dateUtils';
import {tableDateTimeFormat as dateTimeFormat} from 'components/featuredGroupList/utils/dateUtils';
import featuredGroupRoutes from 'routes/programming.routes';
import {getPrefixedUrl} from 'routes';

const maxItems = 5;
const defaultImage = 'http://pluto.tv.s3.amazonaws.com/assets/images/default/channel.id-featured-default.jpg';

interface IFeaturedGroupsListPopoverProps {
  row: IFeaturedGroup;
  onEdit: (groupId: string) => void;
  maxChannels?: number;
}

const ImageWrapper = ({src, alt}: {src?: string; alt?: string}) => {
  return (
    <AssembleImageWrapper
      backgroundColor='black'
      height='75px'
      width='130px'
      src={rewriteImageUrl(src || defaultImage)}
      alt={alt}
    />
  );
};

const FeaturedGroupsListPopover = ({
  row,
  onEdit,
  maxChannels = maxItems,
}: IFeaturedGroupsListPopoverProps): React.ReactElement | null => {
  const {contentItems} = row;
  const remainingItems = (contentItems || []).length - maxChannels;
  const showMoreItems = remainingItems > 0;

  return (
    <Popover appendToBody={true} trigger='mouseenter' triggerDisplay='inline' delay={1000} maxWidth='28.8rem'>
      <Template label='trigger'>
        <TdMultilineTruncate
          row={row}
          text={row.name}
          truncateOnLine={2}
          onClick={row => onEdit(row.id)}
          url={getPrefixedUrl(featuredGroupRoutes.paths.featuredGroupEditDetailsPage.replace(':id', row.id))}
          popover={false}
        />
      </Template>
      <Template label='popover'>
        <Box padding='medium' background='charcoal' minWidth='28.125rem'>
          <Stack space='small'>
            <Cluster align='flex-end' space='medium'>
              <Heading level='h3' truncate={true} truncateBackgroundHover='charcoal'>
                {row.name}
              </Heading>
              <Heading level='h5' color='secondary'>
                {formatDateWithTimezone(row.start, dateTimeFormat)}
              </Heading>
            </Cluster>
            <Divider color='graphite' />
            {(contentItems || []).length > 0 ? (
              <>
                <Stack space='small'>
                  {contentItems.slice(0, maxItems).map(contentItem => {
                    return (
                      <Cluster key={contentItem.content.id} space='small' align='center' wrap={false}>
                        <ImageWrapper
                          src={rewriteImageUrl(contentItem.content.featuredImage?.path)}
                          alt={contentItem.content.name}
                        />
                        <TdMultilineTruncate row={row} truncateOnLine={2} text={contentItem.content.name || ''} />
                      </Cluster>
                    );
                  })}
                </Stack>
                {showMoreItems && (
                  <Box marginTop='medium'>
                    <Stack space='medium'>
                      <Divider color='graphite' />
                      <TdLink row={row} title={`+${remainingItems} More`} onClick={row => onEdit(row.id)} />
                    </Stack>
                  </Box>
                )}
              </>
            ) : (
              <Box padding='medium' background='charcoal' height='100px' marginTop='xxxlarge'>
                <Cluster space='xxxxlarge' align='center' justify='center' wrap={false}>
                  <Heading level='h3'>No channels currently assigned.</Heading>
                </Cluster>
              </Box>
            )}
          </Stack>
        </Box>
      </Template>
    </Popover>
  );
};

export default FeaturedGroupsListPopover;
