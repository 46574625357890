import * as React from 'react';
import {useHistory} from 'react-router-dom';
import {differenceBy, orderBy, sortBy, uniqBy} from 'lodash-es';
import {
  Box,
  Button,
  Center,
  Checkbox,
  Cluster,
  Click,
  Cover,
  Dialog,
  Divider,
  Drawer,
  Expand,
  FormItem,
  Heading,
  Help,
  Icon,
  ISelectOption,
  Label,
  Notification,
  Pagination,
  Paragraph,
  RadioGroup,
  Select,
  Sidebar,
  Spinner,
  Stack,
  Status,
  Table,
  TdLink,
  Template,
  TextInput,
  Toast,
  trimModel,
  useValidateForm,
  ITableCol,
} from '@pluto-tv/assemble';

import {useAppPermissions} from 'app/permissions';
import vodCollectionRoutes from 'routes/programming.routes';
import {TableActions} from 'components/tableActions';
import CrudError from 'components/crudError';
import {VodCollectionFavoriteSearch} from 'components/favoriteSearch/favoriteSearch';

import {
  useFindQuery,
  useInsertMutation,
  useUpdateMutation,
  useBulkUpdateMutation,
  useBulkReorderMutation,
} from 'features/vodCollections/vodCollectionsApi';
import {useFindQuery as useFindMainCategoriesQuery} from 'features/mainCategories/mainCategoriesApi';
import {useFindQuery as useFindDevicesQuery} from 'features/devices/devicesApi';

import {IMainCategory} from 'models/mainCategories';
import {IVodCollection, IVodOrder} from 'models/vodCollections';
import {
  vodCollectionSearchValidator,
  vodCollectionCreateValidator,
  vodCollectionDeviceUpdateValidator,
} from '../../views/programming/vodCollections/validators';
import {defaultSearch, useVodCollectionSearch} from '../../views/programming/vodCollections/utils';
import {useUserRegions} from 'helpers/useUserRegions';
import {useLanguageList} from 'helpers/useLanguageList';
import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import {IListPayload} from 'models/generic';
import {IUserVODCollectionSearch} from 'models/users';
import useToggleSearchBarOnSlash from 'helpers/useToggleSearchBarOnSlash';

const vodTitleId = 'vodTitle';
import {withThousandsSeparator} from 'utils/thousands-separator';
import {getPrefixedUrl} from 'routes';

const TABLE_COLUMN_NAME = {
  'VOD Collection Name': 'name',
  'Collection Name': 'name',
  'Display Name': 'displayName',
  'Active Region': 'activeRegion',
  'Main Categories': 'categories',
  Hero: 'heroCarousel',
  Published: 'enabled',
  'Kids Only': 'kidsMode',
  'Office Only': 'plutoOfficeOnly',
  Titles: 'titleCount',
  Order: 'order',
} as const;

interface SearchState {
  page: number;
  rowsPerPage: number;
  searchObj: Partial<IVodCollection>;
  sortCol: keyof typeof TABLE_COLUMN_NAME;
  sortDir: 'asc' | 'dsc';
}

const searchReducer = (prevState: SearchState, data: Partial<SearchState>) => {
  return {
    ...prevState,
    ...data,
  };
};

export interface IVodCollectionListProps {
  actionsCol?: boolean;
  addNewVodCollection?: boolean;
  deviceUpdates?: boolean;
  checkboxCol?: true | 'multiple';
  inModal?: boolean;
  nameTarget?: React.HTMLAttributeAnchorTarget;
  onSelect?: () => void;
  reorder?: boolean;
  showFavoriteSearch?: boolean;
  isSearchExpanded?: boolean;
}

const VodCollectionList = React.memo(
  ({
    actionsCol = true,
    addNewVodCollection = true,
    deviceUpdates = true,
    checkboxCol = 'multiple',
    inModal = false,
    reorder = true,
    showFavoriteSearch = true,
    isSearchExpanded = false,
  }: IVodCollectionListProps) => {
    const history = useHistory();
    const {ableTo, permissions} = useAppPermissions();
    const {activeRegions, isSuccess: isSuccessRegions} = useUserRegions();

    const userActiveRegions = activeRegions.map(ac => ac.code);

    const [insertVodCollection] = useInsertMutation();

    const [searchQuery, searchQueryDispatch] = React.useReducer<React.Reducer<SearchState, Partial<SearchState>>>(
      searchReducer,
      {page: 0, rowsPerPage: 25, searchObj: {}, sortCol: 'Order', sortDir: 'asc'},
    );

    const {page, rowsPerPage, searchObj, sortCol, sortDir} = searchQuery;
    const [userSearch, setUserSearch] = React.useState<IUserVODCollectionSearch | undefined>();

    const [isEmptySearch, setIsEmptySearch] = React.useState(true);
    const [searchExpanded, setSearchExpanded] = React.useState(isSearchExpanded);
    const [isLoading, setIsLoading] = React.useState(true);
    const [mainCategoriesList, setMainCategoriesList] = React.useState<ISelectOption[]>([]);
    const [filteredDeviceIncludedList, setFilteredDeviceIncludedList] = React.useState<ISelectOption[]>([]);
    const [filteredDeviceExcludedList, setfilteredDeviceExcludedList] = React.useState<ISelectOption[]>([]);
    const [vodCollectionData, setVodCollectionData] = React.useState<IListPayload<IVodCollection> | undefined>(
      undefined,
    );
    const [rowsToReorder, setRowsToReorder] = React.useState<IVodCollection[]>([]);
    const [isDirtyOrder, setIsDirtyOrder] = React.useState(false);
    const [selectedRegionOrder, setSelectedRegionOrder] = React.useState<string[] | undefined>(undefined);

    const [selectedRow, setSelectedRow] = React.useState<IVodCollection[]>([]);
    const [distributionCol, setDistributionCol] = React.useState<string>('include');
    const [selectedDistributionGroup, setSelectedDistributionGroup] = React.useState<string[]>([]);
    const [isSettingRowsToReorder, setIsSettingRowsToReorder] = React.useState(true);

    useToggleSearchBarOnSlash(setSearchExpanded, searchExpanded);
    const {
      form,
      model,
      onBlur,
      onChange,
      setFields,
      reset: resetCreate,
      state: formState,
    } = useValidateForm<IVodCollection>(vodCollectionCreateValidator, 'onBlur');

    const {reset: resetDeviceUpdate} = useValidateForm<IVodCollection>(vodCollectionDeviceUpdateValidator, 'onBlur');

    const {data: deviceTypesList, isFetching: isDevicesTypesFetching} = useFindDevicesQuery({
      offset: 0,
      limit: 250,
      sort: 'name:asc',
    });

    const uniqueDeviceTypes = orderBy(
      [...new Map(deviceTypesList?.data.map(item => [item['platform'], item])).values()].map(d => ({
        label: `${d.platform}`,
        value: d._id,
      })),
      'label',
    );

    const {
      data: vodCollection,
      isFetching,
      isError,
      error,
    } = useFindQuery(
      {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        sort: `${TABLE_COLUMN_NAME[sortCol]}:${sortDir}`,
        ...searchObj,
      },
      {
        refetchOnMountOrArgChange: true,
        skip: Object.keys(searchObj).length === 0 || !!selectedRegionOrder,
      },
    );

    const {
      data: vodCollectionOrder,
      isFetching: isFetchingOrder,
      isError: isErrorOrder,
    } = useFindQuery(
      {
        offset: 0,
        limit: 2000,
        sort: `${TABLE_COLUMN_NAME[sortCol]}:${sortDir}`,
        ...searchObj,
        activeRegions: selectedRegionOrder,
      },
      {
        refetchOnMountOrArgChange: true,
        skip: Object.keys(searchObj).length === 0 || selectedRegionOrder === undefined,
      },
    );

    const {
      data: mainCategories,
      isError: isMainCategoriesError,
      error: mainCategoriesError,
      isFetching: isMainCategoriesFetching,
    } = useFindMainCategoriesQuery();

    const {isLanguagesError, languagesError, isLanguagesFetching} = useLanguageList();

    const {
      model: searchModel,
      onChange: searchOnChange,
      setModel: setSearchModel,
      setFields: setSearchFields,
      form: searchForm,
      state: searchState,
      onBlur: searchOnBlur,
    } = useValidateForm<IVodCollection>(vodCollectionSearchValidator, 'immediate');

    const orderByLabel = valueToMap => {
      return orderBy(
        valueToMap.map(label => ({label, value: label})),
        ['label'],
      );
    };

    React.useEffect(() => {
      const deviceIncludedOptionList = orderByLabel([...new Set(deviceTypesList?.data.map(d => d.platform))]);
      const deviceExludedOptionList = orderByLabel([...new Set(deviceTypesList?.data.map(d => d.platform))]);

      const platformsFilteredDeviceIncluded = orderByLabel(
        deviceIncludedOptionList.flatMap(a => a.label).filter(i => !searchModel.devicesExcluded?.includes(i)),
      );
      const platformsFilteredDeviceExcluded = orderByLabel(
        deviceExludedOptionList.flatMap(a => a.label).filter(i => !searchModel.devicesIncluded?.includes(i)),
      );

      setFilteredDeviceIncludedList(platformsFilteredDeviceIncluded);
      setfilteredDeviceExcludedList(platformsFilteredDeviceExcluded);
    }, [searchModel.devicesExcluded, searchModel.devicesIncluded, deviceTypesList?.data]);

    const handleSearchSelected = (userSearch: IUserVODCollectionSearch) => {
      // visually check or uncheck published checkbox
      searchOnChange('enabled', !!userSearch.model.enabled);
      setUserSearch(userSearch);
      setSearchModel(userSearch?.model || {});
    };

    React.useEffect(() => {
      setIsLoading(isFetching);
      if (isError && !isFetching) {
        setVodCollectionData({metadata: {offset: 0, limit: 25, totalCount: 0}, data: []});
      } else {
        setVodCollectionData(vodCollection);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching]);

    const handleFilteredList = (mainCategoriesArray: IMainCategory[], activeRegion: string) => {
      const list = mainCategoriesArray
        .filter((mc: IMainCategory) => mc.activeRegion === activeRegion)
        .map((mc: IMainCategory) => ({label: mc.name, value: mc.id, order: mc.order}));

      return sortBy(list, 'label');
    };

    React.useEffect(() => {
      setIsLoading(isFetchingOrder);
      if (!isFetchingOrder) {
        setIsSettingRowsToReorder(false);
      }
    }, [isFetchingOrder]);

    React.useEffect(() => {
      if (isErrorOrder && !isFetchingOrder) {
        setRowsToReorder([]);
      } else if (vodCollectionOrder?.data?.length ?? 0 > 0) {
        setRowsToReorder((vodCollectionOrder?.data || []).filter(obj => obj.order !== 0));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetchingOrder]);

    React.useEffect(() => {
      const list: ISelectOption[] = handleFilteredList(mainCategories || [], model.activeRegion as string);
      setMainCategoriesList(list);
    }, [model.mainCategories, model.activeRegion, mainCategories]);

    React.useEffect(() => {
      if (searchExpanded) {
        setTimeout(() => {
          const vodTitleInput = document.getElementById(vodTitleId);
          vodTitleInput?.focus({
            preventScroll: true,
          });
        });
      }
    }, [searchExpanded]);

    const handleCreate = async (navigateTo = false): Promise<string | undefined> => {
      let vodCollectionId: string | undefined;

      try {
        setIsCreating(true);
        const postModel: Partial<IVodCollection> = {
          ...model,
          enabled: false,
          plutoOfficeOnly: true,
          kidsMode: false,
          number: 0,
          archived: false,
          categories: model.categories?.map(c => ({...c, order: 0})),
        };

        const newVodCollection = await insertVodCollection(trimModel(postModel, 'name')).unwrap();
        vodCollectionId = newVodCollection.id!;

        const toastMsg = navigateTo ? (
          'VOD Collection Created'
        ) : (
          <Stack space='xxsmall'>
            <Paragraph>VOD Collection Created</Paragraph>
            <Click
              underline={true}
              hoverColor='white'
              onClick={() =>
                history.push(
                  vodCollectionRoutes.paths.vodCollectionEditDetailsPage.replace(':id', vodCollectionId as string),
                )
              }
            >
              View VOD Collection: {postModel.name}
            </Click>
          </Stack>
        );

        Toast.success('Success', toastMsg, 8000);
        setCreateOpen(false);

        searchQueryDispatch({page: 0});
      } catch (e) {
        Toast.error('Error', (e as any).data.message);
      } finally {
        setIsCreating(false);
      }

      return vodCollectionId;
    };

    const handleCreateAndEdit = async () => {
      try {
        const vodCollectionId = await handleCreate(true);

        if (vodCollectionId) {
          history.push(
            vodCollectionRoutes.paths.vodCollectionEditDetailsPage.replace(':id', vodCollectionId as string),
          );
        }
      } catch (e) {}
    };

    const handleCancel = () => {
      setCreateOpen(false);
    };

    const handleEdit = (vodCollection: IVodCollection) => {
      history.push(vodCollectionRoutes.paths.vodCollectionEditProgramPage.replace(':id', vodCollection?.id));
    };

    const handleReorderCancel = () => {
      setIsDirtyOrder(false);
      model.activeRegion = '';
      setReorderOpen(false);
    };

    const changeRowOrder = (arr: IVodCollection[]) => {
      return arr.map((item, index) => {
        return {...item, order: index + 1};
      });
    };

    const handleReorderSave = async (rows: IVodCollection[]) => {
      const reordered = changeRowOrder(rows);
      await handleOrderUpdate(reordered.map(el => ({id: el.id, order: el.order})));
      setIsDirtyOrder(false);
      setIsLoading(true);
      model.activeRegion = '';
      setReorderOpen(false);
      setSelectedRegionOrder(undefined);
    };

    const [updateVodCollection] = useUpdateMutation();

    const handleArchive = React.useCallback(
      async (archivedVodCollection: IVodCollection) => {
        const archiveRequestData: Partial<IVodCollection> = {
          archived: true,
        };
        const fields: string[] = ['archived'];

        const {enabled, id} = archivedVodCollection;
        // enabled => published for VOD
        if (enabled) {
          archiveRequestData.enabled = false;
          archiveRequestData.order = undefined;
          fields.push('enabled', 'order');
        }

        try {
          const result: any = await updateVodCollection({
            id: id,
            vodCollection: archiveRequestData,
            fields,
          });

          if ('error' in result) Toast.error('error', `${result.error.data.message}.`);
          else Toast.success('Success', `You've archived the VOD Collection ${archivedVodCollection.name}.`);
          return result;
        } catch (e) {
          Toast.error('Error', 'Failed to archive VOD Collection. Please try again');
        }
      },
      [updateVodCollection],
    );

    /**
     * custom response message for bulk update devices operation.
     **/
    const customResultMessage = React.useCallback(() => {
      const selectedDevicesCount = (model.platform as string[]).length!;
      const firstThreeDeviceNames = model.platform?.slice(0, 3);
      const firstThreeVodCollectionNames = selectedRow.slice(0, 3).map(n => n.name);

      const bulkUpdatedToastMsg = {
        vodCollectionName:
          selectedRow.length > 3
            ? `${firstThreeVodCollectionNames} and ${selectedRow.length - 3} other collections`
            : `${firstThreeVodCollectionNames} collection`,
        platformName:
          selectedDevicesCount > 3
            ? `${firstThreeDeviceNames} and ${selectedDevicesCount - 3} other devices`
            : `${firstThreeDeviceNames} device`,
      };

      if (distributionCol === 'exclude') {
        return `You've excluded ${bulkUpdatedToastMsg.platformName} from ${bulkUpdatedToastMsg.vodCollectionName}.`;
      }

      if (distributionCol === 'remove') {
        return `You've removed ${bulkUpdatedToastMsg.platformName} from ${bulkUpdatedToastMsg.vodCollectionName}.`;
      }

      return `You've included ${bulkUpdatedToastMsg.platformName} to ${bulkUpdatedToastMsg.vodCollectionName}.`;
    }, [model.platform, selectedRow, distributionCol]);

    const [updateVodDeviceTypesBulk] = useBulkUpdateMutation();
    const [updateVodOrderBulk] = useBulkReorderMutation();

    const updateDevicesInBulk = React.useCallback(async () => {
      try {
        const bulkUpdatedVodCollections = await updateVodDeviceTypesBulk({
          devices: selectedDistributionGroup,
          includeGroup: distributionCol === 'include' ? selectedRow.map(v => v.id) : [],
          excludeGroup: distributionCol === 'exclude' ? selectedRow.map(v => v.id) : [],
          removeGroup: distributionCol === 'remove' ? selectedRow.map(v => v.id) : [],
        });

        setDeviceUpdateOpen(false);
        setSelectedRow([]);
        setSelectedDistributionGroup([]);

        if ('error' in bulkUpdatedVodCollections) {
          if ((bulkUpdatedVodCollections.error as any)?.data.error) {
            Toast.error('error', `${(bulkUpdatedVodCollections.error as any)?.data.error}.`);
          } else {
            Toast.error('error', `${bulkUpdatedVodCollections.error}.`);
          }
        } else Toast.success('Success', customResultMessage());
        return bulkUpdatedVodCollections;
      } catch (e) {
        Toast.error('Error', 'Failed to perform device updates. Please try again');
      }
    }, [updateVodDeviceTypesBulk, selectedDistributionGroup, distributionCol, selectedRow, customResultMessage]);

    const handleUnarchive = React.useCallback(
      async (archivedVodCollection: IVodCollection) => {
        const archiveRequestData: Partial<IVodCollection> = {
          archived: false,
        };
        const fields: string[] = ['archived'];

        const {id} = archivedVodCollection;
        try {
          const result: any = await updateVodCollection({
            id: id,
            vodCollection: archiveRequestData,
            fields,
          });

          if ('error' in result) Toast.error('error', `${result.error.data.message}.`);
          else Toast.success('Success', `You've unarchived the VOD Collection ${archivedVodCollection.name}.`);
          return result;
        } catch (e) {
          Toast.error('Error', 'Failed to unarchive VOD Collection. Please try again');
        }
      },
      [updateVodCollection],
    );

    const handleOrderUpdate = React.useCallback(
      async (reorderedVodCollection: IVodOrder[]) => {
        try {
          const result: any = await updateVodOrderBulk({orderElements: reorderedVodCollection});

          if ('error' in result) {
            Toast.error('error', `${result.error.data.message}.`);
            return;
          }

          Toast.success('Successfully reordered VOD Collection');

          return result;
        } catch (e) {
          Toast.error('Error', 'Failed to reorder VOD Collection. Please try again');
        }
      },
      [updateVodOrderBulk],
    );

    const truncate = (text: string, max: number) => (text.length > max ? `${text.substring(0, max)}...` : text);

    React.useEffect(() => {
      if (isSuccessRegions) {
        searchOnChange('enabled', true);

        searchQueryDispatch({
          searchObj: {
            activeRegions: userActiveRegions,
            enabled: true,
          },
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccessRegions, activeRegions]);

    const [createOpen, setCreateOpen] = React.useState(false);
    const openCreate = () => {
      resetCreate();
      setCreateOpen(true);
    };

    const [reorderOpen, setReorderOpen] = React.useState(false);

    const openReorder = () => {
      setReorderOpen(true);
      const onlyRowData = (vodCollectionOrder?.data || []).filter(obj => obj.order !== 0);
      setRowsToReorder(onlyRowData);
    };

    const [deviceUpdateOpen, setDeviceUpdateOpen] = React.useState(false);
    const openDeviceUpdate = () => {
      resetDeviceUpdate();
      setDeviceUpdateOpen(true);
    };

    const closeDeviceUpdate = React.useCallback(() => {
      resetDeviceUpdate();
      setSelectedDistributionGroup([]);
      resetCreate();
      setDeviceUpdateOpen(false);
    }, [resetDeviceUpdate, resetCreate]);

    const {clearSearch} = useVodCollectionSearch();

    const initialSearch = () => {
      setSearchModel(defaultSearch);
      setIsEmptySearch(true);
      setUserSearch(undefined);
      clearSearch(true);
    };

    const changeSort = (sortDir, colName) => {
      searchQueryDispatch({
        sortDir: sortDir !== 'asc' ? 'dsc' : 'asc',
        sortCol: TABLE_COLUMN_NAME[colName] ? colName : 'Order',
        page: 0,
      });
    };

    const [isCreating, setIsCreating] = React.useState(false);

    const handleVodSearchBarClear = React.useCallback(() => {
      setSearchModel({});
      setUserSearch(undefined);

      setTimeout(() =>
        searchQueryDispatch({
          searchObj: {
            activeRegions: userActiveRegions,
          },
          page: 0,
        }),
      );
    }, [setSearchModel, userActiveRegions]);

    const handleVodSearchBarSearch = () => {
      const searchActiveRegions =
        (searchModel.activeRegions || []).length === 0 ? userActiveRegions : searchModel.activeRegions;

      searchQueryDispatch({
        searchObj: {
          ...searchModel,
          activeRegions: searchActiveRegions,
        },
        page: 0,
      });
    };

    React.useEffect(() => {
      const searchObjWithUserActiveRegion = {
        ...userSearch?.model,
        activeRegions: (searchModel.activeRegions || []).length === 0 ? userActiveRegions : searchModel.activeRegions,
      };
      searchQueryDispatch({
        searchObj: searchObjWithUserActiveRegion,
        sortCol: (userSearch?.sortCol || 'Order') as keyof typeof TABLE_COLUMN_NAME,
        sortDir: userSearch?.sortDir || 'asc',
        page: 0,
      });
      // avoid adding userActiveRegions in dependency hook not to trigger api multiple time.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userSearch]);

    if (isError && (error as FetchBaseQueryError).status != 404) {
      return <CrudError error='Error loading page data' />;
    }

    if (isMainCategoriesError || isLanguagesError) {
      const err = mainCategoriesError || languagesError;
      return <CrudError error={err} />;
    }

    if (isMainCategoriesFetching || isLanguagesFetching || isDevicesTypesFetching) {
      return (
        <Box fullHeight={true}>
          <Spinner center={true} minHeight='9.375rem' size='xlarge' />
        </Box>
      );
    }
    const onDrop = (key, value, orig, dest) => {
      const newOrder = [...rowsToReorder];
      const row = newOrder.splice(orig, 1)[0];
      newOrder.splice(dest, 0, row);
      setIsDirtyOrder(true);
      setRowsToReorder(changeRowOrder(newOrder));
    };

    return (
      <Sidebar fullHeight={true}>
        <Expand width='18.75rem' height='100%' fullHeightContainer={true} isExpanded={searchExpanded}>
          <Template label='expandable'>
            <Box
              background='pewter'
              paddingY={inModal ? 'none' : 'medium'}
              paddingRight='medium'
              paddingLeft={inModal ? 'none' : 'medium'}
              fullHeight={true}
            >
              <Cover scrolling={true} gutter='medium'>
                <Template label='header'>
                  <Stack space='medium'>
                    <Cluster align='center' justify='space-between'>
                      <Icon icon='tune' space='small' size='large' iconAlign='center'>
                        <Heading level='h4'>Search Filters</Heading>
                      </Icon>
                      <Icon icon='collapseleft' size='large' onClick={() => setSearchExpanded(!searchExpanded)} />
                    </Cluster>
                    {showFavoriteSearch && (
                      <VodCollectionFavoriteSearch
                        searchModel={{
                          name: '',
                          model: searchModel,
                          sortCol: sortCol,
                          sortDir: sortDir,
                        }}
                        searchSelected={userSearch}
                        onSearchSelected={handleSearchSelected}
                        onClearSelection={handleVodSearchBarClear}
                      />
                    )}
                    {/* <div>Favorite searchs</div> */}
                    <Divider color='graphite' />
                  </Stack>
                </Template>
                <Template label='cover'>
                  <form
                    onSubmit={ev => {
                      ev.preventDefault();
                      setTimeout(() => handleVodSearchBarSearch());
                    }}
                  >
                    <Stack space='small'>
                      {/* Using this to allow pressing enter to submit form */}
                      <input type='submit' style={{display: 'none'}} />
                      <Stack space='xlarge'>
                        <Stack space='small'>
                          <Label>Status</Label>
                          <Stack space='small'>
                            <Checkbox
                              label='Published'
                              onChange={val => {
                                /**
                                 * COM-2960 : Different selection strategies.
                                 * archived selected = both(published + unpublished) should be unchecked.
                                 * published or unpublished = allowed selection.
                                 */
                                if (searchModel.archived) {
                                  setSearchFields({archived: !val});
                                }
                                setSearchFields({enabled: val});
                              }}
                              value={searchModel.enabled}
                            />
                            <Checkbox
                              label='Unpublished'
                              onChange={val => {
                                if (searchModel.archived) {
                                  setSearchFields({archived: !val});
                                }
                                setSearchFields({unpublished: val});
                              }}
                              value={searchModel.unpublished}
                            />
                            <Checkbox
                              label='Archived'
                              onChange={val => {
                                if (searchModel.enabled || searchModel.unpublished) {
                                  setSearchFields({enabled: !val, unpublished: !val});
                                }
                                setSearchFields({archived: val});
                              }}
                              value={searchModel.archived}
                            />
                          </Stack>
                        </Stack>
                        <FormItem
                          {...searchForm.name}
                          onBlur={() => {
                            searchOnBlur('name');
                          }}
                        >
                          <TextInput
                            id={vodTitleId}
                            clearable={true}
                            placeholder='Title'
                            value={searchModel.name}
                            onChange={val => {
                              searchOnChange('name', val);
                            }}
                          />
                        </FormItem>
                      </Stack>
                      <FormItem {...searchForm.activeRegion}>
                        <Select
                          clearable={true}
                          multiselect={true}
                          placeholder='Select Active Region'
                          value={(searchModel.activeRegions || [])?.map(d => ({label: d, value: d}))}
                          onChange={val =>
                            setSearchFields({
                              activeRegions: (val || []).map(v => v.value),
                            })
                          }
                          options={activeRegions.map(ar => ({
                            label: `${ar.name} (${ar.code})`,
                            value: ar.code,
                          }))}
                          predicate='value'
                        />
                      </FormItem>
                      <FormItem {...searchForm.devicesIncluded}>
                        <Select
                          id='deviceIncluded'
                          clearable={true}
                          placeholder='Select Devices'
                          multiselect={true}
                          value={searchModel.devicesIncluded?.map(p => ({label: p, value: p}))}
                          options={filteredDeviceIncludedList}
                          onChange={value => {
                            setSearchFields({
                              devicesIncluded: ((value as ISelectOption[]) || []).map(v => v.value),
                            });
                          }}
                          predicate='value'
                          searchable={true}
                          onSearch={term =>
                            orderBy(
                              (filteredDeviceIncludedList || [])
                                .filter(p => p.label.toLowerCase().startsWith(term.toLowerCase()))
                                .map(p => ({label: p.label, value: p.value}), 'label'),
                            ) || ([] as ISelectOption[])
                          }
                        />
                      </FormItem>
                      <FormItem {...searchForm.devicesExcluded}>
                        <Select
                          id='deviceExcluded'
                          clearable={true}
                          placeholder='Select Devices'
                          multiselect={true}
                          value={searchModel.devicesExcluded?.map(p => ({label: p, value: p}))}
                          options={filteredDeviceExcludedList}
                          onChange={value => {
                            setSearchFields({
                              devicesExcluded: ((value as ISelectOption[]) || []).map(v => v.value),
                            });
                          }}
                          predicate='value'
                          searchable={true}
                          onSearch={term =>
                            orderBy(
                              (filteredDeviceExcludedList || [])
                                .filter(p => p.label.toLowerCase().startsWith(term.toLowerCase()))
                                .map(p => ({label: p.label, value: p.value}), 'label'),
                            ) || ([] as ISelectOption[])
                          }
                        />
                      </FormItem>
                    </Stack>
                  </form>
                </Template>
                <Template label='footer'>
                  <Cluster justify='space-between'>
                    <div></div>
                    <Cluster space='small'>
                      <Button
                        ghost={true}
                        state={isFetching ? 'disabled' : ''}
                        onClick={() => handleVodSearchBarClear()}
                      >
                        Clear
                      </Button>
                      <Button
                        type='primary'
                        state={!searchState.isDirty ? 'disabled' : isFetching ? 'thinking' : ''}
                        onClick={handleVodSearchBarSearch}
                      >
                        Search
                      </Button>
                    </Cluster>
                  </Cluster>
                </Template>
              </Cover>
            </Box>
          </Template>
        </Expand>
        <Cover
          scrolling={true}
          gutter='large'
          coverTemplateHeight='100%'
          overflow='auto'
          padding={inModal ? 'none' : {mobile: 'medium', wide: 'large'}}
        >
          <Template label='header'>
            <Cluster justify='space-between' align='center' space='medium'>
              <Cluster align='end' space='small'>
                <Heading level='h1'>VOD Collections</Heading>
                <Cluster space='xxsmall' align='center'>
                  <Icon
                    icon='tune'
                    space='xxxsmall'
                    verticalAlign='bottom'
                    lineHeight='0px'
                    onClick={() => setSearchExpanded(!searchExpanded)}
                  >
                    {withThousandsSeparator(vodCollectionData?.metadata.totalCount || 0)} Items
                  </Icon>
                  {!isEmptySearch && !isLoading && (
                    <Icon
                      icon='cancel'
                      space='xxxsmall'
                      verticalAlign='bottom'
                      lineHeight='0px'
                      onClick={initialSearch}
                    >
                      Clear Filter
                    </Icon>
                  )}
                </Cluster>
              </Cluster>
              <Cluster space='small' align='center'>
                {reorder && (
                  <>
                    <Button onClick={() => openReorder()} permission={permissions.VOD_EDIT}>
                      Reorder
                    </Button>
                    <Dialog isOpen={reorderOpen} onClose={() => handleReorderCancel()} width='100%' height='100%'>
                      <Template label='header'>
                        <Cluster justify='space-between' fullWidth={true}>
                          <Stack space='small'>
                            <Heading level='h2'>Reorder VOD Collection List</Heading>
                            <Help state='warning'>
                              Reordering these VOD Collections is separate from Main Categories.
                            </Help>
                          </Stack>
                          <Cluster space='small' align='center'>
                            <Label>Active Region</Label>
                            <Select
                              onChange={ac => {
                                setIsSettingRowsToReorder(true);
                                setSelectedRegionOrder([ac.value.toUpperCase()]);
                                setFields({activeRegion: ac.label});
                              }}
                              value={{label: model.activeRegion || ''}}
                              id='regionForReorder'
                              width='15rem'
                              options={activeRegions.map(ar => ({
                                label: `${ar.name} (${ar.code})`,
                                value: ar.code.toLowerCase(),
                              }))}
                              placeholder='Select Active Region'
                            />
                          </Cluster>
                        </Cluster>
                      </Template>
                      <Template label='body'>
                        {!model.activeRegion && (
                          <Cover center={true}>
                            <Template label='cover'>
                              <Center maxWidth='31.5rem'>
                                <Notification type='info'>
                                  Select an Active Region to show published VOD Collections.
                                </Notification>
                              </Center>
                            </Template>
                          </Cover>
                        )}
                        {model.activeRegion && (
                          <Table
                            emptyMsg='No VOD collections to reorder for the selected active region.'
                            loading={isSettingRowsToReorder}
                            fixedHeader={true}
                            wrapContent={true}
                            onDrop={onDrop}
                            draggable={true}
                            dragKey='key'
                            dropKeys={['key']}
                            onSelect={row => setSelectedRow(row as any)}
                            predicate='id'
                            selectable={true}
                            cols={[
                              {
                                label: 'VOD Collection Name',
                                sortable: false,
                                colMinWidth: '25.125rem',
                                transform: row => (
                                  <TdLink
                                    row={row}
                                    title={row.name}
                                    target='_blank'
                                    url={getPrefixedUrl(
                                      vodCollectionRoutes.paths.vodCollectionEditDetailsPage.replace(':id', row?.id),
                                    )} // Hiding program tab for 2.8.0
                                    onClick={handleEdit}
                                  />
                                ),
                              },
                              {
                                label: 'Display Name',
                                transform: row => row?.displayName,
                                sortable: false,
                                colMinWidth: '13rem',
                              },
                              {
                                label: 'Active Region',
                                transform: row => row?.activeRegion?.toUpperCase(),
                                sortable: false,
                                colMinWidth: '9.6875rem',
                              },
                              {
                                label: 'Main Categories',
                                transform: row => row.categories.map(c => c.name!).join(' + '),
                                sortable: false,
                                colMinWidth: '13rem',
                              },
                              {
                                label: 'Hero',
                                sortable: false,
                                colMinWidth: '5.875rem',
                                transform: row => (
                                  <Status
                                    label={row.heroCarousel ? 'Yes' : 'No'}
                                    state={row.heroCarousel ? 'success' : 'neutral'}
                                  />
                                ),
                              },
                              {
                                label: 'Kids Only',
                                sortable: false,
                                colMinWidth: '6.875rem',
                                transform: row => (
                                  <Status
                                    label={row.kidsMode ? 'Yes' : 'No'}
                                    state={row.kidsMode ? 'success' : 'neutral'}
                                  />
                                ),
                              },
                              {
                                label: 'Office Only',
                                sortable: false,
                                colMinWidth: '8rem',
                                transform: row => (
                                  <Status
                                    label={row.plutoOfficeOnly ? 'Yes' : 'No'}
                                    state={row.plutoOfficeOnly ? 'success' : 'neutral'}
                                  />
                                ),
                              },
                              {
                                label: 'Titles',
                                transform: row => row?.titleCount,
                                sortable: false,
                                colMinWidth: '4.875rem',
                              },
                              {
                                label: 'Order',
                                transform: row => row?.order,
                                sortable: false,
                                colMinWidth: '4.875rem',
                              },
                            ]}
                            rows={rowsToReorder}
                          >
                            <Template label='loading'>
                              <Cluster space='small' align='center'>
                                <Spinner />
                                <Paragraph>Loading VOD Collection List</Paragraph>
                              </Cluster>
                            </Template>
                            <Template label='empty'>
                              <Notification type='warning'>
                                There are no VOD Collections currently available.
                              </Notification>
                            </Template>
                          </Table>
                        )}
                      </Template>
                      <Template label='footer'>
                        <Cluster justify='space-between'>
                          <div></div>
                          <Cluster space='small'>
                            <Button ghost={true} onClick={handleReorderCancel} id='cancelReorderButton'>
                              Cancel
                            </Button>
                            <Button
                              type='primary'
                              // This will need to be changed to watch for changes on the table order
                              state={!isDirtyOrder ? 'disabled' : isCreating ? 'thinking' : ''}
                              onClick={() => handleReorderSave(rowsToReorder)}
                              id='reorderSaveButton'
                            >
                              Save Order
                            </Button>
                          </Cluster>
                        </Cluster>
                      </Template>
                    </Dialog>
                  </>
                )}
                {deviceUpdates && (
                  <>
                    {/* This button has a state of disabled until at least one checkbox on the table is checked */}
                    <Button
                      onClick={() => openDeviceUpdate()}
                      permission={permissions.VOD_EDIT}
                      state={selectedRow.length > 0 ? '' : 'disabled'}
                    >
                      Device Updates
                    </Button>
                    <Drawer width='24rem' isOpen={deviceUpdateOpen} onClose={closeDeviceUpdate}>
                      <Box
                        paddingY={inModal ? 'none' : 'medium'}
                        paddingRight='medium'
                        paddingLeft={inModal ? 'none' : 'medium'}
                      >
                        <Stack space='medium'>
                          <Heading level='h4'>Device Updates</Heading>
                          <Divider color='graphite' />
                          <Stack space='xsmall'>
                            <Stack>
                              <FormItem child='RadioGroup' label='Update Type'>
                                <RadioGroup
                                  layout='horizontal'
                                  value={{value: distributionCol, label: 'Include'}}
                                  options={[
                                    {label: 'Include', value: 'include'},
                                    {label: 'Exclude', value: 'exclude'},
                                    {label: 'Remove', value: 'remove'},
                                  ]}
                                  onChange={value => setDistributionCol(value.value)}
                                />
                              </FormItem>
                              <FormItem label='Select Devices'>
                                <Select
                                  id='distribution'
                                  visualStyle='light'
                                  multiselect={true}
                                  clearable={true}
                                  value={selectedDistributionGroup.map(v => ({value: v, label: v}))}
                                  options={uniqueDeviceTypes}
                                  onChange={value => {
                                    setSelectedDistributionGroup(((value as ISelectOption[]) || []).map(v => v.value));
                                    setFields({
                                      distribution: {
                                        [distributionCol]: ((value as ISelectOption[]) || []).map(v => v.value),
                                      },
                                      platform: (value || []).map(v => v.label),
                                    });
                                  }}
                                  predicate='value'
                                  searchable={true}
                                  onSearch={term =>
                                    orderBy(
                                      (uniqueDeviceTypes || [])
                                        .filter(p => p.label.toLowerCase().startsWith(term.toLowerCase()))
                                        .map(p => ({label: p.label, value: p.value}), 'label'),
                                    ) || ([] as ISelectOption[])
                                  }
                                />
                              </FormItem>
                            </Stack>
                            <Cluster justify='space-between'>
                              <div></div>
                              <Cluster space='small'>
                                <Button onClick={closeDeviceUpdate}>Cancel</Button>
                                <Button
                                  type='primary'
                                  state={selectedDistributionGroup.length > 0 ? '' : 'disabled'}
                                  onClick={() => updateDevicesInBulk()}
                                >
                                  Save Updates
                                </Button>
                              </Cluster>
                            </Cluster>
                          </Stack>
                        </Stack>
                      </Box>
                    </Drawer>
                  </>
                )}
                {addNewVodCollection ? (
                  <>
                    <Button type='primary' onClick={() => openCreate()} permission={permissions.VOD_CREATE}>
                      + New VOD Collection
                    </Button>
                    <Dialog isOpen={createOpen} onClose={() => setCreateOpen(false)} width='42.8125rem' height='30rem'>
                      <Template label='header'>
                        <Heading level='h2'>Create VOD Collection</Heading>
                      </Template>
                      <Template label='body'>
                        <Stack space='small'>
                          <FormItem {...form.name} onBlur={() => onBlur('name')}>
                            <TextInput
                              onChange={value => {
                                onChange('name', value);
                              }}
                              id='title'
                            />
                          </FormItem>
                          <FormItem {...form.displayName} onBlur={() => onBlur('displayName')}>
                            <TextInput onChange={value => onChange('displayName', value)} id='title' />
                          </FormItem>
                          <FormItem {...form?.activeRegion} onBlur={() => onBlur('activeRegion')}>
                            <Select
                              onChange={value => {
                                const activeRegion = (value as ISelectOption).value;
                                setFields({
                                  activeRegion,
                                });
                              }}
                              value={{label: model.activeRegion, value: model.activeRegion} as ISelectOption}
                              id='activeRegtion'
                              appendToBody={true}
                              predicate='value'
                              options={activeRegions.map(ar => ({
                                label: `${ar.name} (${ar.code})`,
                                value: ar.code.toLowerCase(),
                              }))}
                            />
                          </FormItem>
                          <FormItem {...form?.categories} onBlur={() => onBlur('categories')}>
                            <Select
                              searchPlaceholder='Search for main category'
                              onChange={value =>
                                setFields({
                                  categories: (value || [])?.map(v => {
                                    return {
                                      catId: v.value,
                                      order: v.order,
                                    };
                                  }),
                                })
                              }
                              value={
                                model.categories?.map(d => ({
                                  label: d.name,
                                  value: d.catId,
                                  order: d.order,
                                })) as ISelectOption[]
                              }
                              appendToBody={true}
                              id='categories'
                              multiselect={true}
                              clearable={true}
                              addAll={true}
                              predicate='value'
                              searchable={true}
                              onSearch={val =>
                                orderBy(
                                  (mainCategoriesList || [])
                                    .filter(mc => mc.label.toLowerCase().startsWith(val.toLowerCase()))
                                    .map(mc => ({label: mc.label, value: mc.value, order: mc.order}), 'label'),
                                ) || []
                              }
                              options={mainCategoriesList}
                            />
                          </FormItem>
                        </Stack>
                      </Template>
                      <Template label='footer'>
                        <Cluster justify='space-between'>
                          <div></div>
                          <Cluster space='small'>
                            <Button ghost={true} onClick={handleCancel} id='cancelButton'>
                              Cancel
                            </Button>
                            <Button
                              type='primary'
                              state={
                                !formState.isValid || !formState.isDirty ? 'disabled' : isCreating ? 'thinking' : ''
                              }
                              onClick={() => handleCreate()}
                              id='createButton'
                            >
                              Create
                            </Button>
                            <Button
                              type='primary'
                              state={
                                !formState.isValid || !formState.isDirty ? 'disabled' : isCreating ? 'thinking' : ''
                              }
                              onClick={handleCreateAndEdit}
                              id='createEditButton'
                            >
                              Create and Edit
                            </Button>
                          </Cluster>
                        </Cluster>
                      </Template>
                    </Dialog>
                  </>
                ) : (
                  <Box height='1.7rem'></Box>
                )}
              </Cluster>
            </Cluster>
          </Template>
          <Template label='cover'>
            <Box
              background='pewter'
              borderTop={true}
              borderSize='2px'
              borderColor='cavern'
              paddingTop={inModal ? 'none' : (vodCollection?.data.length as number) > 0 ? 'xsmall' : 'medium'}
              paddingBottom='none'
              paddingX={inModal ? 'none' : 'large'}
              fullHeight={true}
            >
              <Table<IVodCollection>
                loading={isLoading || !vodCollectionData}
                fixedHeader={true}
                wrapContent={true}
                sortDir={sortDir}
                sortCol={sortCol}
                onSort={colName => changeSort(sortDir === 'asc' ? 'dsc' : 'asc', colName)}
                selectable={checkboxCol}
                predicate='id'
                selected={selectedRow as any}
                onSelect={row => {
                  // Only select all or unselect all cases
                  if (row.length === 0) {
                    // Difference
                    setSelectedRow(curr => differenceBy(curr, vodCollection?.data as IVodCollection[], 'id'));
                  }
                  // Union
                  if (row.length === vodCollection?.data.length) {
                    setSelectedRow(curr => uniqBy(curr.concat(row), 'id'));
                  }
                }}
                selectLog={(row, selected) => {
                  setSelectedRow(
                    selected ? curr => uniqBy(curr.concat(row), 'id') : curr => curr.filter(e => e.id !== row.id),
                  );
                }}
                cols={[
                  {
                    label: 'Collection Name',
                    sortable: true,
                    colMinWidth: '15.125rem',
                    transform: row => (
                      <TdLink
                        row={row}
                        title={row.name}
                        url={getPrefixedUrl(
                          vodCollectionRoutes.paths.vodCollectionEditProgramPage.replace(':id', row?.id),
                        )}
                        onClick={handleEdit}
                      />
                    ),
                  },
                  {
                    label: 'Display Name',
                    transform: row => row?.displayName,
                    sortable: true,
                    colMinWidth: '13rem',
                  },
                  {
                    label: 'Active Region',
                    transform: row => row?.activeRegion?.toUpperCase(),
                    sortable: true,
                    colMinWidth: '9.6875rem',
                  },
                  {
                    label: 'Main Categories',
                    transform: row => truncate(row.categories.map(c => c.name!).join(' + '), 75),
                    sortable: true,
                    colMinWidth: '13rem',
                  },
                  {
                    label: 'Hero',
                    sortable: true,
                    colMinWidth: '5.875rem',
                    transform: row => (
                      <Status
                        label={row.heroCarousel ? 'Yes' : 'No'}
                        state={row.heroCarousel ? 'success' : 'neutral'}
                      />
                    ),
                  },
                  {
                    label: 'Published',
                    sortable: true,
                    colMinWidth: '9.5rem',
                    transform: row => (
                      <Status
                        label={row.enabled ? 'Published' : 'Unpublished'}
                        state={row.enabled ? 'success' : 'neutral'}
                      />
                    ),
                  },
                  {
                    label: 'Kids Only',
                    sortable: true,
                    colMinWidth: '7.875rem',
                    transform: row => (
                      <Status label={row.kidsMode ? 'Yes' : 'No'} state={row.kidsMode ? 'success' : 'neutral'} />
                    ),
                  },
                  {
                    label: 'Office Only',
                    sortable: true,
                    colMinWidth: '9rem',
                    transform: row => (
                      <Status
                        label={row.plutoOfficeOnly ? 'Yes' : 'No'}
                        state={row.plutoOfficeOnly ? 'success' : 'neutral'}
                      />
                    ),
                  },
                  {
                    label: 'Titles',
                    transform: row => row.titleCount,
                    sortable: true,
                    colMinWidth: '4.875rem',
                  },
                  {
                    label: 'Order',
                    transform: row => row?.order,
                    sortable: true,
                    colMinWidth: '4.875rem',
                  },
                  ...(ableTo('VOD_EDIT') && actionsCol
                    ? [
                        {
                          label: 'Actions',
                          colWidth: '6.25rem',
                          transform: row => (
                            <TableActions
                              row={row}
                              icons={ableTo('VOD_EDIT') ? ['edit'] : []}
                              altTitle={row.name}
                              archiveOption={!row.archived && ableTo('VOD_EDIT')}
                              unarchiveOption={!row.enabled && row.archived && ableTo('VOD_EDIT')}
                              onClick={(row, icon) => {
                                switch (icon) {
                                  case 'edit':
                                    handleEdit(row);
                                    break;
                                  case 'archive':
                                    handleArchive(row);
                                    break;
                                  case 'unarchive':
                                    handleUnarchive(row);
                                    break;
                                  default:
                                }
                              }}
                            />
                          ),
                        } as ITableCol<IVodCollection>,
                      ]
                    : []),
                ]}
                rows={vodCollectionData?.data}
              >
                <Template label='loading'>
                  <Cluster space='small' align='center'>
                    <Spinner />
                    <Paragraph>Loading VOD Collection List</Paragraph>
                  </Cluster>
                </Template>
                <Template label='empty'>
                  <Notification type='warning'>There are no VOD Collections currently available.</Notification>
                </Template>
              </Table>
            </Box>
          </Template>
          <Template label='footer'>
            <Cluster justify='space-between'>
              <div></div>
              {(vodCollection?.data.length as number) > 0 && (
                <Pagination
                  perPage={rowsPerPage as 25 | 50 | 75 | 100}
                  currentPage={page}
                  total={vodCollectionData?.metadata.totalCount || 0}
                  onPageChange={page => {
                    searchQueryDispatch({
                      page,
                    });
                  }}
                  onPerPageChange={perPage => {
                    searchQueryDispatch({
                      rowsPerPage: perPage,
                      page: 0,
                    });
                  }}
                />
              )}
            </Cluster>
          </Template>
        </Cover>
      </Sidebar>
    );
  },
);

VodCollectionList.displayName = 'VodCollectionList';
export default VodCollectionList;
