import * as React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {
  Box,
  Button,
  Cluster,
  Cover,
  Divider,
  Heading,
  Popover,
  Spinner,
  Stack,
  Status,
  Template,
  Toast,
  useUndo,
  // Toast,
  useValidateForm,
} from '@pluto-tv/assemble';
import {useFindByIdQuery, useUpdateMutation} from 'features/hubConfigs/hubConfigsApi';

import {hubConfigSettingsValidator} from '../validators';

import {useAppPermissions} from 'app/permissions';
import ConfirmRouteChange from 'components/confirmRouteChange';
import programmingRoutes from 'routes/programming.routes';
import {IHubCarousel, IHubConfig} from 'models/hubConfigs';

import HubPreview from './preview';
import HubSettings from './settings';
import {hubSaveParser} from 'views/programming/hubManager/hubs/utils';
import {useKeyboardShortcut} from 'views/programming/channel/edit/program/hooks/useKeyboardShortcut';
// import CrudError from 'components/crudError';

const hubTabs = ['preview', 'settings'] as const;

export type THubTabs = typeof hubTabs[number];

const getActiveTab = (pathname: string): THubTabs => {
  const nestedPath = pathname.slice(pathname.lastIndexOf('/') + 1) as THubTabs;

  if (hubTabs.includes(nestedPath)) {
    return nestedPath;
  }

  return 'preview';
};

export default (): JSX.Element => {
  const {id} = useParams<{id: string}>();
  const history = useHistory();
  const activeTab: THubTabs = getActiveTab(location.pathname);

  const [publishOpen, setPublishOpen] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isLeaving, setIsLeaving] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const {
    data: hubConfigData,
    isError: isHubError,
    error: hubError,
    isLoading: isHubFetching,
  } = useFindByIdQuery(
    {id, populate: true},
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const [updateHub] = useUpdateMutation();

  const {ableTo} = useAppPermissions();
  const canEdit = ableTo('CAROUSEL_EDIT');

  const {
    form,
    model,
    onBlur,
    onChange,
    pristineModel,
    setFields,
    setModel,
    dirtyFields,
    state: formState,
  } = useValidateForm<IHubConfig>([...hubConfigSettingsValidator], 'immediate');

  // Handler for hub initial load or after save
  React.useEffect(() => {
    setModel({
      ...hubConfigData,
      expiresInMilliseconds:
        hubConfigData?.expiresInMilliseconds && hubConfigData.expiresInMilliseconds > 0
          ? hubConfigData.expiresInMilliseconds / 60 / 1000
          : undefined,
    });
    setIsLoading(false);
  }, [hubConfigData, setModel]);

  React.useEffect(() => {
    if (isLeaving) {
      history.push(programmingRoutes.paths.hubManagerPage);
    }
  }, [isLeaving, history]);

  const cancelHandler = () => {
    // For conflicting modals
    setIsLeaving(true);
  };

  const togglePublishOpen = React.useCallback(() => {
    setPublishOpen(prev => !prev);
  }, []);

  const saveData = async () => {
    setIsSaving(true);

    try {
      const postModel = hubSaveParser({...model});
      await updateHub({id: postModel.id || '', hubConfig: postModel}).unwrap();

      Toast.success('Success', 'The Hub was successfully updated', 5000);
    } catch (e: any) {
      let errorMsg = 'Failed to update Hub. Please try again';

      if (e?.data?.validationErrors && e.data?.validationErrors.published) {
        errorMsg = e.data?.validationErrors.published[0];
      } else if (e?.status === 500 && e?.data.message) {
        try {
          const message = JSON.parse(e.data.message)?.message;
          errorMsg = message ? message.charAt(0).toUpperCase() + message.slice(1) : errorMsg;
        } catch (_e) {}
      }

      Toast.error('Error', errorMsg);

      // If publishing the hub fails, the form should show the previous value
      if (!pristineModel.published && model.published) {
        setFields({published: false});
      }
    } finally {
      setIsSaving(false);
    }
  };

  const {set: setUndo, undo, redo, canUndo, canRedo} = useUndo<IHubCarousel[]>();

  React.useEffect(() => {
    if (pristineModel.carousels?.length) {
      setUndo(pristineModel.carousels || [], {locked: true});
    }
  }, [setUndo, pristineModel.carousels]);

  const handleUndo = () => {
    if (!canUndo()) return;

    const previousCarouselsState = undo();
    setFields({carousels: [...(previousCarouselsState || [])]});
  };

  const handleRedo = () => {
    if (!canRedo()) return;

    const carouselsState = redo();
    setFields({carousels: [...(carouselsState || [])]});
  };

  useKeyboardShortcut('ctrl+z, command+z', handleUndo);
  useKeyboardShortcut('shift+ctrl+z, shift+command+z', handleRedo);
  useKeyboardShortcut('ctrl+s, command+s', (event: KeyboardEvent) => {
    event.preventDefault();
    saveData();
  });

  if (isHubFetching || isLoading) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} size='xlarge' />
      </Box>
    );
  }

  if (isHubError) {
    Toast.error('Error', 'There was an error retrieving the Hub. Please try again');
  }

  return (
    <>
      <ConfirmRouteChange
        when={!isLeaving ? formState.isDirty : false}
        onSave={saveData}
        ignoreHashChanges={true}
        isValid={formState.isValid}
      />
      <Cover
        scrolling={true}
        gutter='large'
        coverTemplateHeight='100%'
        paddingX={{mobile: 'medium', wide: 'large'}}
        paddingTop={{mobile: 'medium', wide: 'large'}}
      >
        <Template label='header'>
          <Stack space='medium'>
            <Cluster growNthChild={1} justify='space-between' align='center' space='medium' wrap={false}>
              <Stack space='small'>
                <Heading level='h1' truncate={true} truncateBackgroundHover='shadow' id='title'>
                  {pristineModel.name}
                </Heading>
                <Cluster space='xxsmall' wrap={false} fullWidth={true} id='activeRegion'>
                  <Heading level='h5' color='secondary' whiteSpace='nowrap'>
                    Active Region:
                  </Heading>
                  <Heading
                    level='h5'
                    truncate={true}
                    truncateBackgroundHover='shadow'
                    color='primary'
                    id='activeRegion'
                  >
                    {pristineModel.region?.toUpperCase()}
                  </Heading>
                </Cluster>
              </Stack>
              <Cluster space='small' align='center' wrap={false}>
                <Popover
                  manualTrigger={true}
                  visible={publishOpen}
                  onClickOutside={() => setPublishOpen(false)}
                  allowedPlacements={['bottom', 'bottom-end']}
                >
                  <Template label='trigger'>
                    <Button
                      type='solid'
                      icon='arrowdown'
                      iconPosition='right'
                      onClick={() => togglePublishOpen()}
                      permission={canEdit ? '' : 'disabled'}
                      id='hubStatus'
                    >
                      {model.published ? (
                        <Status label='Published&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' state='success' />
                      ) : (
                        <Status label='Unpublished' state='neutral' />
                      )}
                    </Button>
                  </Template>
                  <Template label='popover'>
                    <Stack space='xxxxsmall'>
                      <Status
                        id='unpublish-button'
                        label='Unpublished'
                        state='neutral'
                        onClick={() => {
                          setFields({published: false});
                          togglePublishOpen();
                        }}
                      />
                      <Status
                        id='publish-button'
                        label='Published'
                        state='success'
                        onClick={() => {
                          setFields({published: true});
                          togglePublishOpen();
                        }}
                      />
                    </Stack>
                  </Template>
                </Popover>
              </Cluster>
            </Cluster>

            <Divider color='mist' marginBottom='xxxxxsmall' />
            <Cluster buttonGroup={true} justify='center' wrap={false}>
              <Button
                active={activeTab === 'preview'}
                onClick={() => history.push(programmingRoutes.paths.hubEditPreviewPage.replace(':id', id))}
                id='previewTab'
              >
                Preview
              </Button>
              <Button
                active={activeTab === 'settings'}
                onClick={() => history.push(programmingRoutes.paths.hubEditSettingsPage.replace(':id', id))}
                id='settingsTab'
              >
                Settings
              </Button>
            </Cluster>
          </Stack>
        </Template>
        <Template label='cover'>
          {activeTab === 'preview' && (
            <HubPreview
              form={form}
              model={model as any}
              onBlur={onBlur as any}
              onChange={onChange as any}
              pristineModel={pristineModel as any}
              setFields={setFields as any}
              dirtyFields={dirtyFields}
              setUndo={setUndo}
              isHubConfigFetching={isHubFetching}
              isHubConfigError={isHubError}
              hubConfigError={hubError}
            />
          )}
          {activeTab === 'settings' && (
            <HubSettings
              form={form}
              model={model as any}
              onBlur={onBlur as any}
              onChange={onChange as any}
              pristineModel={pristineModel as any}
              setFields={setFields as any}
              dirtyFields={dirtyFields}
            />
          )}
        </Template>
        <Template label='footer'>
          <Box background='onyx' paddingX='small' paddingY='small' marginX={{mobile: 'none', wide: 'largeNegative'}}>
            <Cluster justify='space-between'>
              <div></div>
              <Cluster space='xxxsmall'>
                <Button ghost={true} onClick={cancelHandler} id='discard' permission={canEdit ? '' : 'disabled'}>
                  Discard
                </Button>
                <Button
                  type='primary'
                  state={!formState.isValid || !formState.isDirty ? 'disabled' : isSaving ? 'thinking' : ''}
                  onClick={saveData}
                  id='save'
                  permission={canEdit ? '' : 'disabled'}
                >
                  Save Changes
                </Button>
              </Cluster>
            </Cluster>
          </Box>
        </Template>
      </Cover>
    </>
  );
};
