import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

import {isCommandCenter} from 'helpers/isCommandCenter';

export interface IAuthenticationResult {
  jwt: string;
}

export const authenticationApi = createApi({
  reducerPath: 'authentication',
  baseQuery: fetchBaseQuery({
    baseUrl: isCommandCenter ? '/cms/api/v1/' : '/v1/',
  }),
  endpoints(builder) {
    return {
      authenticate: builder.mutation<IAuthenticationResult, string>({
        query(accessToken) {
          return `auth?accessToken=${accessToken}`;
        },
      }),
    };
  },
});

export const {useAuthenticateMutation} = authenticationApi;
