const formatString = (n: number): string => {
  return n > 9 ? '' + n : '0' + n;
};

const DEFAULT_FRAMERATE = 30;

export const isDropFrame = (framerate = DEFAULT_FRAMERATE): boolean => framerate === 29.97 || framerate === 59.94;

/** `getBreakpointFromMSToTimecode` from inf-lib-schemas */
export const msToTimecode = (ms: number, framerate = DEFAULT_FRAMERATE, rollover = true): string => {
  const useFramerate = framerate && framerate > 0 ? framerate : DEFAULT_FRAMERATE;
  const dropFrame = isDropFrame(useFramerate);

  // 23.976 or 23.98 => 24
  // 29.97 => 30
  // 59.94 => 60
  const intFramerate = Math.ceil(useFramerate);

  // we cannot have fractional framerates.
  // If the computation gives 10001.5 frames, we are still in frame 10001
  const frames = Math.floor((useFramerate * ms) / 1000);

  let dropFrames = 0;

  if (dropFrame)
    // Number of frames to drop on the minute marks is the nearest
    // integer to 6% of the framerate
    dropFrames = Math.round(useFramerate * 0.066666);

  // Number of frames in an hour
  const framesPerHour = Math.round(useFramerate * 60 * 60);
  // Number of frames in a day - timecode rolls over after 24 hours
  const framesPer24Hours = framesPerHour * 24;
  // Number of frames per ten minutes
  const framesPer10Minutes = Math.round(useFramerate * 60 * 10);
  // Number of frames per minute is the round of the framerate * 60 minus
  // the number of dropped frames
  const framesPerMinute = Math.round(useFramerate) * 60 - dropFrames;

  let frameNumber = frames;

  // If frame number is greater than 24 hrs, next operation will rollover clock
  if (rollover) {
    frameNumber %= framesPer24Hours;
  }

  if (dropFrame) {
    const d = Math.floor(frameNumber / framesPer10Minutes);
    const m = frameNumber % framesPer10Minutes;
    if (m > dropFrames) frameNumber += dropFrames * 9 * d + dropFrames * Math.floor((m - dropFrames) / framesPerMinute);
    else frameNumber += dropFrames * 9 * d;
  }

  const frs = frameNumber % intFramerate;

  const secs = Math.floor(frameNumber / intFramerate) % 60;
  const mins = Math.floor(Math.floor(frameNumber / intFramerate) / 60) % 60;
  const hrs = Math.floor(Math.floor(Math.floor(frameNumber / intFramerate) / 60) / 60);

  return `${formatString(hrs)}:${formatString(mins)}:${formatString(secs)}${dropFrame ? ';' : ':'}${formatString(frs)}`;
};

export const timecodeToMs = (timecode: string, framerate = DEFAULT_FRAMERATE): number => {
  const useFramerate = framerate && framerate > 0 ? framerate : DEFAULT_FRAMERATE;
  const isDropFrameTimecode = /[;.]/.test(timecode);

  // 23.976 or 23.98 => 24
  // 29.97 => 30
  // 59.94 => 60
  const intFramerate = Math.ceil(useFramerate);

  // HH:MM:SS:FF or HH:MM:SS;FF
  const [hours, minutes, seconds, frames] = timecode.split(/[^0-9]/).map(value => parseInt(value, 10));

  // Number of frames per hour (non-drop)
  const framesPerHour = intFramerate * 60 * 60;

  // Number of frames per minute (non-drop)
  const framesPerMinute = intFramerate * 60;

  // Number of frames to drop
  let framesToDrop = 0;
  if (isDropFrameTimecode) {
    // Total number of minutes
    const totalMinutes = 60 * hours + minutes;

    // Number of drop frames is 6% of framerate rounded to nearest integer.
    // http://www.davidheidelberger.com/blog/?p=29
    const magicNumer = Math.round(useFramerate * 0.066666);
    framesToDrop = magicNumer * (totalMinutes - Math.floor(totalMinutes / 10));
  }

  const framesNumber =
    framesPerHour * hours + framesPerMinute * minutes + intFramerate * seconds + frames - framesToDrop;

  // a floor or round can give a lower time which can be in a different timecode
  // a ceil always ensures that in the least, the time that needs to have passed to be in the right timecode has passed.
  return Math.ceil((framesNumber / useFramerate) * 1000);
};
